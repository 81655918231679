import { Bugsnag } from '@core/tools/bugs/Bugsnag';
import type { HandleClientError } from '@sveltejs/kit';

/**
 * Error hooks
 */
export const handleError: HandleClientError = (({ error, event }) => {
	const errorId = crypto.randomUUID();

	let message = 'Unexpected Client Error';
	if (error instanceof Error) {
		message = error.message;
	}

	Bugsnag.reportError('client-hook', new Error(message), { error, event, errorId });

	return {
		message: 'An unexpected error occurred.',
		errorId
	};
}) satisfies HandleClientError;
