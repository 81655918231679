import { PUBLIC_BUGSNAG_API, PUBLIC_ENV_MODE } from '$env/static/public';
import { default as BugsnagService } from '@bugsnag/js';
import type { BugsService } from './Bugs';
import { browser } from '$app/environment';

if (!PUBLIC_BUGSNAG_API) throw new Error('Missing PUBLIC_BUGSNAG_API .env variable');

if (PUBLIC_ENV_MODE !== 'development') {
	BugsnagService.start(PUBLIC_BUGSNAG_API);
}

class BugsnagWrapper implements BugsService {
	public reportError(id: string, error: Error, event: { [key: string]: unknown }): void {
		if (PUBLIC_ENV_MODE !== 'development') {
			BugsnagService.notify(error, function (e) {
				e.addMetadata(id, event);
			});
		} else {
			console.error(id, error, event);
		}
	}

	public breadcrumb(data: { [key: string]: any }): void {
		const { message, ...rest } = data;
		if (PUBLIC_ENV_MODE !== 'development') {
			BugsnagService.leaveBreadcrumb(message || 'Breadcumb', rest, browser ? 'manual' : 'process');
		} else {
			console.log(message || 'Breadcumb', rest);
		}
	}
}

export const Bugsnag = new BugsnagWrapper();
